<template>
  <div class="main-bar w-full p-2">
    <div class="relative text-md flex justify-center w-full p-2 rounded-md"
    style="box-shadow: 0 0 .5em rgba(0, 0, 0, 0.3)"
    :style="{
      backgroundColor: status=='error'?'#e96e6e':status=='success'?'#50c53f':status=='alert'?'#F36711':'#DDDD',
      color: status=='error'?'#FFF':status=='success'?'#FFF':'#FFF',
    }">
      <div class="font-bold absolute right-0 mr-3" @click="$emit('exit')">✖</div>
      <slot></slot>
    </div>
    
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  computed:{
    ...mapGetters({
      status: 'topMessageStatus'
    })
  },
  mounted() {
    setTimeout(()=>{
      this.$emit('exit')
    },3000)
  },
}
</script>

<style scoped>
.main-bar{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}
.close-btn{
  float: right;
  color: white;
  padding: .3em 1em;
  cursor: pointer;
}
</style>