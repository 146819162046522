<template>
  <div>
    <PageTitle :title="''" :subtitle="''" >
      <div class="title-cont ms-t-s">
        <div v-for="(ppLanguage, index) in ppLanguages" :key="index"
        :class="{'selected': selectedIndex == index}"
        @click="selectedIndex = index; getDataFromConfig()">
          {{ppLanguage.title}}
        </div>
      </div>      
    </PageTitle>
    <BodyBox class="content-box l-t-a">
      <div v-html="fetchedData"></div>
    
    </BodyBox>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import PageTitle from '@/components/PageTitle'
import BodyBox from '@/components/BodyBox'
import ExpandableRow from '@/components/ExpandableRow'

export default {
  components:{
    PageTitle,
    BodyBox,
    ExpandableRow
  },
  data() {
    return {
      ppLanguages: [
        {
          title: 'BAHASA MALAYSIA',
          key: 'troopers_chat_privacy_policy_bm',
        },
        {
          title: 'ENGLISH',
          key: 'troopers_chat_privacy_policy_eng',
        },
      ],
      selectedIndex: 0,
      fetchedData: null
    }
  },
  methods:{
    ...mapActions({
      getSysConfig: 'getSysConfig',
    }),
    getDataFromConfig(){
      this.fetchedData = null
      this.getSysConfig(this.ppLanguages[this.selectedIndex].key)
      .then(res=>{
        this.fetchedData = res.data.value
      })
      .catch(err => {
        console.log(err)
      })
    }
  },
  mounted() {
    this.getDataFromConfig()
  },
}
</script>

<style scoped>


.title-cont{
  display: flex;
  height: 100%;
  align-items: center;
}
.title-cont>div{
  color: white;
  margin-left: 1em;
  cursor: pointer;
}
.title-cont>div.selected{
  border-bottom: 2px white solid;
  font-weight: 700;
  cursor: default;
}
.content-box{
  width: 100%;
  border-radius: .5em;
  position: relative;
  z-index: 1;
  font-size: .5em;
}

</style>