<template>
  <div 
  id="g-recaptcha"
  class="g-recaptcha"
  :data-sitekey="sitekey"
  :data-action="action"
  data-size="invisible">
  </div>
</template>

<script>
export default {
  name: "GoogleRecaptcha",
  data () {
    return {
      widgetId: 0
    }
  },
  props: ["action"],
  computed: {
    sitekey() {
      return this.GOOGLE_CAPTCHA_SITE_KEY;
    }
  }, 
  methods: {
    async execute () {
      grecaptcha.enterprise.ready(async () => {
        const token = await grecaptcha.enterprise.execute(this.widgetId);
        this.$emit('verify', token)
        this.reset();
      })
    },
    reset () {
      window.grecaptcha.enterprise.reset(this.widgetId)
    },
    render () {
      if (window.grecaptcha?.enterprise) {
        this.widgetId = window.grecaptcha.enterprise.render('g-recaptcha', {
          sitekey: this.sitekey,
          size: 'invisible',
          action: this.action,
          // the callback executed when the user solve the recaptcha
          // callback: (response) => {
          //   console.log(response)
          //   // emit an event called verify with the response as payload
          //   this.$emit('verify', response)
          //   // reset the recaptcha widget so you can execute it again
          //   this.reset() 
          // }
        })
      } 
    },
  },
  mounted () {
    if (typeof window.grecaptcha?.enterprise === "undefined") {
        var script = document.createElement("script");
        script.src = `https://www.google.com/recaptcha/enterprise.js`;
        script.async = true;
        script.defer = true;
        document.head.appendChild(script);
    }
    // render the recaptcha widget when the component is mounted
    this.render()
  }
}
</script>