import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./style.css";

import TextBox from "./components/TextBox";
import BtnSubmit from "./components/BtnSubmit";
import BtnEnter from "./components/BtnEnter";
import ContBox from "./components/ContBox";
import mixins from "./lib/util.js";
import * as VueGoogleMaps from "vue2-google-maps";
import VueGeolocation from "vue-browser-geolocation";
import { library } from "@fortawesome/fontawesome-svg-core";

import { faMobileAndroid, faAppleAlt, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(faMobileAndroid);
library.add(faAppleAlt);
library.add(faSpinner);

Vue.config.productionTip = false;
Vue.mixin(mixins);
Vue.component("TextBox", TextBox);
Vue.component("BtnSubmit", BtnSubmit);
Vue.component("BtnEnter", BtnEnter);
Vue.component("ContBox", ContBox);
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_API_KEY,
    libraries: "places", // necessary for places input
  },
});
Vue.use(VueGeolocation);
Vue.component("font-awesome-icon", FontAwesomeIcon);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
