<template>
    <div class="flex">
        <img v-for="(num, index) in 5" :key="index" :src="index>=languageDetails.level?require('@/assets/star-off.svg'):require('@/assets/star-on.svg')" :style="{ height: size == 'mid' ? '1.5em' : '1em'}"
        @click="$emit('level-selected',index)"
        alt="">
    </div>
</template>

<script>
export default {
    name: 'language-rating',
    props: ['languageDetails', 'size','selectable'],
}
</script>