<template>
  <div class="cont"> 
    <div  class="title">
      <img src="@/assets/404.svg" alt="">
    </div>
    <div class="info">
      OOPS! THE PAGE YOU WERE LOOKING FOR, COULDN'T BE FOUND.
    </div>
    <div>
      <img  src="@/assets/designers.svg" alt="">
    </div>
      <BtnEnter v-on:clicked="goBack" :enabled="true" >Go back</BtnEnter>
  </div>
</template>

<script>
export default {
    methods:{
      goBack(){
        this.$router.push('/')
      },
  }
}
</script>

<style scoped>
.cont{
  position: absolute;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
}
.title{
  margin: .5em;
}
.info{
  font-size: .9em;
  margin: 1em;
}
</style>