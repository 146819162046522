<template>
  <div>
    <div>
      <label>
        <div v-if="search" class="w-full flex gap-2 items-center mb-2">
            <gmap-autocomplete
            ref="gmapAutocomplete"
            id="gmap_search"
            @place_changed="currentPlace=$event; multiLocation?addMarker('select'):false"
            class="search-txt m-t-s border border-gray p-1 rounded-md">
            </gmap-autocomplete>
            <BtnSubmit :enabled="currentPlace" class="serch-btn" v-on:clicked="addMarker('add')">{{btnTitle}}</BtnSubmit>
            <!-- <div v-if="multiLocation" class="add-btn" :class="currentPlace?'enabled':'disabled'" @click="addMarker('add')"><span>+</span></div> -->
        </div>
      </label>

    </div>
    <gmap-map ref="map"
      :center="center"
      :zoom="15"
      style="width:100%; height:100%;"
      :options="{
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: true,
        streetViewControl: false,
        rotateControl: true,
        fullscreenControl: false,
        disableDefaultUI: false
      }">
      <!-- <GmapCluster > -->
        <gmap-marker
          :key="index"
          v-for="(m, index) in selectedLocations"
          :position="m.position"
          @click="center=m.position"
        ></gmap-marker>
      <!-- </GmapCluster> -->

      <gmap-marker
        v-if="searchedMarker"
        :position="searchedMarker.position"
        @click="center=searchedMarker.position"
        icon="https://mts.googleapis.com/vt/icon/name=icons/spotlight/spotlight-waypoint-a.png&psize=11&font=fonts/Roboto-Regular.ttf&color=ff333333&ax=44&ay=48&scale=1"
      ></gmap-marker>
      <GmapCircle v-if="circle&&selectedLocations.length"
        :center="selectedLocations[0].position"
        :radius="200"
        :visible="true"
        :options="{fillColor:'blue',fillOpacity:0.2, strokeOpacity: '0'}"
      ></GmapCircle>
    </gmap-map>
  </div>
</template>

<script>
import { gmapApi } from 'vue2-google-maps'
export default {
  name: "GoogleMap",
  props: ['search', "selectedLocations", "circle", "multiLocation", "btnTitle"],
  data() {
    return {
      // default to Montreal to keep it simple
      // change this to whatever makes sense
      center: { lat: 3.1132884, lng: 101.5767683 },
      currentPlace: null,
      searchedMarker: null,
    };
  },
  mounted() {
    this.geolocate();
    if(this.selectedLocations?.length){
      this.center = this.selectedLocations[0].position
    }
    this.$refs.map.$mapPromise.then((map) => {
        let b = new window.google.maps.LatLngBounds()
        let markers = this.selectedLocations;
        
        for (let index = 0; index < markers.length; index++) {
          const position = markers[index].position;
          b.extend(position);
        }
        this.$refs.map.fitBounds(b)
    })
  
  },

  methods: {
    addMarker(action) {
      if (this.currentPlace) {
        const marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng(),
          address: this.currentPlace.formatted_address,
          name: this.currentPlace.name
        }
        if(action == 'add'){
          this.$emit('locationMarked', { position: marker })
          this.searchedMarker = null
          this.currentPlace = null;
        }
        else{
          this.searchedMarker = { position: marker }
        }
        this.center = marker;
      }
    },
    focusLocation(marker){
      this.$refs.gmapAutocomplete.$refs.input.value = marker.address
      this.center = marker
    },
    newInput(){
      this.$refs.gmapAutocomplete.$refs.input.value = ''
      this.$refs.gmapAutocomplete.$refs.input.focus()
    },
    geolocate: function() {
      navigator.geolocation.getCurrentPosition(position => {
        if(this.search){
            this.center = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };
        }
      });
    },
  }
};
</script>

<style scoped>

.search-txt{
    width: 100%;
}
.serch-btn{
}
.add-btn{
  min-width: 1.2em;
  min-height: 1.2em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: .5em;
  color: rgb(61, 173, 61);
  font-size: 1.8em;
  line-height: 0;
  border: 2px rgb(61, 173, 61) solid;
  border-radius: 50%;
  transition: background-color .3s;
  cursor: pointer;
}
.add-btn.enabled:hover{
  color: white;
  background: rgb(61, 173, 61);
}
.add-btn.enabled{
  color: rgb(61, 173, 61);
  border: 2px rgb(61, 173, 61) solid;
  cursor: default;
}
.add-btn.disabled{
  color: gray;
  border: 2px gray solid;
  cursor: default;
}
</style>
