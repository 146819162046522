<template>
  <div :style="{
    display: singleLine ? 'flex' : 'block',
    alignItems: 'center'
  }">
    <div v-if="title" class="l-t-a b-t-w title w-max whitespace-nowrap">{{title}}<span v-if="optional" class="text-sm text-dark-gray font-light"> (optional)</span></div>
    <div class="input-box rounded p-1" :style="`width:${width};height:${height}`">
      <label ref="imgInput" v-if="type == 'file'" class="file-upload">
        <input @input="handleInputFile($event)" type="file" :accept="accept || '*'">
        <div class="l-t-a gray-t-c">{{inputValue ? inputValue.name : 'Select a file'}}</div>
        <btn-enter style="float: right;">Upload a file</btn-enter>
      </label>
      <textarea v-else-if="type == 'textarea'" v-model="inputValue" 
      ref="inputElm" 
      @input="handleInput"
       :min="min"
       :max="max"
       :maxlength="max"
       :placeholder="placeHolder" 
       :disabled="disabled"
       class="input l-t-s" :class="resize?'resize-y':'resize-none'"/>
      <input v-else :type="type" v-model="inputValue"
      ref="inputElm" 
      @input="handleInput"
       :min="min"
       :max="max"
       :maxlength="max"
       :placeholder="placeHolder"
       :disabled="disabled"
       class="input l-t-s" :class="{'cursor-not-allowed': disabled }"/>
      <div class="flex gray-t-c" :class="type=='textarea'?'items-start':'items-center'">
        <div v-if="inputCounter && max" class="m-t-s gray-t-c">{{inputValue ? inputValue.length : 0}}/{{max}}</div>
        <div v-if="clearable && inputValue" @click="clearInput" class="cursor-pointer pl-1" >✖</div>
      </div>
    </div>
    <div v-if="error" class="text-xs text-red-110 text-left">{{customError||error}}</div>
  </div>
</template>

<script>
export default {
  name: "inputBox",
  props: [
    'value',
    'type',
    'clearable',
    'title',
    'placeHolder',
    'singleLine',
    "min",
    "max",
    "inputCounter",
    "accept",
    "customError",
    "width",
    "height",
    "resize",
    "disabled",
    "optional",
  ],
  data() {
    return {
      inputValue: this.value,
    }
  },
  computed: {
    error(){
      if(this.min && this.inputValue < this.min){
        return `Value must be more than ${this.min}`
      }
      if(this.max && this.inputValue > this.max){
        return `Value must be less than ${this.max}`
      }
      return null
    }
  },
  methods:{
    handleInput (e) {
      this.$emit('input', this.inputValue)
    },
    handleInputFile (e) {
      this.inputValue = e?.target?.files?.[0]
      this.$emit('input',this.inputValue)
    },
    clearInput(){
      this.inputValue = null
      this.$emit('input', this.inputValue)
    },
    selectFile(){
      this.$refs.imgInput?.click()
    }
  },
  watch:{
    value(val){
      this.inputValue = val
    }
  }
}
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  opacity: 1;
}
.textarea {
  resize: none;
}
.input-box{
  width: 100%;
  display: flex;
  background: white;
  border: 1px lightgray solid;
}
.title{
  margin-right: .5em;
}
.input{
  width: 100%;
  padding: 0;
  margin: 0;
  outline: none;
  border: none;
}
.side-box{
  display: flex;
  align-items: center;
}
.side-box>*{
  margin-left: .5em;
  width: max-content;
}
input[type="file"] {
    display: none;
}
.file-upload{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>