<template>
    <div @keyup.enter="triggerReCaptcha"
    class="bg-light-gray w-screen h-screen relative flex flex-col gap-8 justify-center items-center px-4">
        <img src="@/assets/header-logo-2.svg" alt="Troopers Logo" class="w-sm max-w-full block md:hidden px-6">
        <div class="w-full md:w-fit bg-white p-4 md:p-10 rounded-lg shadow-center-lg flex justify-between items-stretch gap-8">
            <div ref="loginForm" class="flex-1 w-full md:w-md flex flex-col gap-4">
                <h2 class="text-2xl">Login</h2>
                <ErrorComment v-if="errorMessage">{{errorMessage}}</ErrorComment>
                <div>
                    <p class="text-left text-md sm:text-lg font-bold">Select Country</p>
                    <div class="flex border border-gray rounded overflow-hidden items-center">
                        <img v-if="currentCountry" :src="currentCountry.flag" :alt="`${currentCountry.name} flag`"
                        class="w-4 h-4 mx-2">
                        <select v-model="selectedCountryName"
                        class="flex-1 text-md sm:text-base h-7"
                        @input="countryChanged($event)">
                            <option v-for="country in countries" :key="country.code" :value="country.name"
                            class="block">
                                <div class="flex gap-2">
                                    <img :src="country.flag" :alt="`${country.name} flag`"
                                    class="w-4 h-4 mx-2" :style="`background: url(${country.flag}) center / contain no-repeat;`">
                                    <p>{{country.name}}</p>
                                </div>
                            </option>
                        </select>
                    </div>
                </div>
                <input-box ref="emailInput" v-model="email" type="email" title="Email" class="text-md sm:text-lg"/>
                <input-box type="password" v-model="password" title="Password" class="text-md sm:text-lg"/>
                <div class="flex flex-wrap gap-2 items-center justify-between">
                    <BtnSubmit v-on:clicked="triggerReCaptcha" :enabled="!submitting">Login</BtnSubmit>
                    <router-link to="forgotPassword" class="text-md underline text-blue-600">Forgot your password?</router-link>
                </div>
            </div>
            <div class="hidden md:block w-0 border-r border-gray"></div>
            <div class="self-center hidden md:flex flex-col items-center justify-center gap-4 px-4">
                <img src="@/assets/header-logo.svg" alt="Troopers" class="w-32">
                <img src="@/assets/header-logo-2.svg" alt="Troopers Logo" class="w-32">
            </div>
        </div>
        <GoogleRecaptcha ref="recaptcha" action="login" @verify="postLogin"/>
    </div>
</template>

<script>
import ErrorComment from '@/components/ErrorComment'
import InputBox from '@/components/InputBox'
import GoogleRecaptcha from '@/components/GoogleRecaptcha.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
    name: 'login',
    components:{
        ErrorComment,
        InputBox,
        GoogleRecaptcha,
    },
    computed: {
        ...mapGetters({
            countries: "countries",
            currentCountry: "currentCountry",
        }),
    },
    data(){
        return{
            email: null,
            password: null,
            errorMessage: null,
            submitting: false,
            selectedCountryName: null,
        }
    },
    methods:{
        ...mapActions({
            login: "login",
            getCountries: "getCountries",
        }),
        countryChanged(e){
            const newVal = e.target.value
            if(newVal){
                if(!newVal) return
                const redirectCountry = this.countries.find(x => x.name == newVal)
                if (redirectCountry){
                    console.log(`${redirectCountry.webDomain}/login`)
                    window.location.href = `${redirectCountry.webDomain}/login`
                }
            }
        },
        triggerReCaptcha(){
            this.$refs.recaptcha.execute('login')
        },
        postLogin(token) {
            this.submitting = true
            this.errorMessage = false
            this.login({email: this.email, password: this.password, token })
            .then(results => {
                if(results.status==200){

                }
            })
            .catch(err => {
                this.errorMessage = err.response.data.message
            })
            .finally(() => {
                this.submitting = false
            })
        },
    },
    mounted(){
        this.getCountries()
    },
    watch: {
        currentCountry(newVal){
            if(newVal){
                this.selectedCountryName = newVal.name
            }
        },
    }
}
</script>

<style scoped>
    
</style>