<template>
  <div>
    <PageTitle :title="''" :subtitle="'Frequently Asked Questions'"></PageTitle>
    <BodyBox class="content-box">
      <div v-for="(section, scetionIndex) in sections" :key="scetionIndex" class="section-box" :id="section.ID">
        <div class="l-t-a vl-t-s b-t-w title-t-c section-title line-height: 1.2em;" :class="section?.faqs ? '': 'text-4xl'">{{section.section}}</div>
        <ExpandableRow v-for="(faq) in section.faqs" :key="faq.index" :item="faq" :id="faq.ID || null" v-on:clicked="clickedItem(faq.index)" :expandedItem="expandedItem" class="faq"></ExpandableRow>
      </div>
    </BodyBox>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import PageTitle from '@/components/PageTitle'
import BodyBox from '@/components/BodyBox'
import ExpandableRow from '@/components/ExpandableRow'
export default {
  components:{
    PageTitle,
    BodyBox,
    ExpandableRow
  },
  data() {
    return {
      sections: null,
      expandedItem: -1,
      fetchedCampaignData: null
    }
  },
  methods:{
    ...mapActions({
      getSysConfig: 'getSysConfig',
    }),
    clickedItem(index){
      if(index == this.expandedItem){
        this.expandedItem = -1
      }
      else{
        this.expandedItem = index
      }
    },
    getFAQsFromConfig() {
      this.getSysConfig("general_faqs")
        .then((res) => {
          const parsedData = JSON.parse(res.data.value);
          this.sections = parsedData.sections;
          this.setupFAQs();
        })
        .catch((err) => {
          console.log("Error in getting FAQs");
        });
    },
    setupFAQs() {
      let newIndex = 1;
      for (let i = 0; i < this.sections.length; i++) {
        const section = this.sections[i];

        console.log(section?.faqs?.length);
        if (section?.faqs?.length) {
          for (let j = 0; j < section.faqs.length; j++) {
            section.faqs[j].index = newIndex;
            newIndex++;
          }
        }
      }
      if (this.$route?.hash != "") {
        let elmnt = document.getElementById(this.$route?.hash.substring(1));
        if (elmnt) {
          for (const section of this.sections) {
            const faq = section?.faqs?.find((faq) => faq.ID == this.$route.hash.substring(1));
            if (faq) {
              this.expandedItem = faq.index;
            }
            }
          setTimeout(() => {
            elmnt.scrollIntoView({
              behavior: "smooth",
            });
          }, 100);
        }
      }
    },
  },
  mounted() {
    this.getFAQsFromConfig()
  },
}
</script>

<style scoped>
.content-box{
  width: 100%;
  border-radius: .5em;
  position: relative;
  z-index: 1;
  max-width: 50em;
}
.section-box{
  margin-bottom: 1em;
}
.section-title{
  line-height: 1.2em;
  margin-bottom: .3em;
}
.faq{
  margin-bottom: .5em;
}
ol{
  margin-left: .1em;
}

</style>