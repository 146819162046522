var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('label',[(_vm.search)?_c('div',{staticClass:"w-full flex gap-2 items-center mb-2"},[_c('gmap-autocomplete',{ref:"gmapAutocomplete",staticClass:"search-txt m-t-s border border-gray p-1 rounded-md",attrs:{"id":"gmap_search"},on:{"place_changed":function($event){_vm.currentPlace=$event; _vm.multiLocation?_vm.addMarker('select'):false}}}),_c('BtnSubmit',{staticClass:"serch-btn",attrs:{"enabled":_vm.currentPlace},on:{"clicked":function($event){return _vm.addMarker('add')}}},[_vm._v(_vm._s(_vm.btnTitle))])],1):_vm._e()])]),_c('gmap-map',{ref:"map",staticStyle:{"width":"100%","height":"100%"},attrs:{"center":_vm.center,"zoom":15,"options":{
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: true,
      streetViewControl: false,
      rotateControl: true,
      fullscreenControl: false,
      disableDefaultUI: false
    }}},[_vm._l((_vm.selectedLocations),function(m,index){return _c('gmap-marker',{key:index,attrs:{"position":m.position},on:{"click":function($event){_vm.center=m.position}}})}),(_vm.searchedMarker)?_c('gmap-marker',{attrs:{"position":_vm.searchedMarker.position,"icon":"https://mts.googleapis.com/vt/icon/name=icons/spotlight/spotlight-waypoint-a.png&psize=11&font=fonts/Roboto-Regular.ttf&color=ff333333&ax=44&ay=48&scale=1"},on:{"click":function($event){_vm.center=_vm.searchedMarker.position}}}):_vm._e(),(_vm.circle&&_vm.selectedLocations.length)?_c('GmapCircle',{attrs:{"center":_vm.selectedLocations[0].position,"radius":200,"visible":true,"options":{fillColor:'blue',fillOpacity:0.2, strokeOpacity: '0'}}}):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }