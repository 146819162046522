<template>
  <div>
    <PageTitle :title="''" :subtitle="'Troubleshooting Troopers App'"></PageTitle>
    <BodyBox class="content-box">
      <div v-for="(section, scetionIndex) in sections" :key="scetionIndex" class="section-box">
        <div class="l-t-a m-t-s b-t-w title-t-c section-title">{{section.section}}</div>
        <ExpandableRow v-for="(issue) in section.issues" :key="issue.index" :item="issue" v-on:clicked="clickedItem(issue.index)" :expandedItem="expandedItem" class="issue"></ExpandableRow>
      </div>
    </BodyBox>
  </div>
</template>

<script>
import PageTitle from '@/components/PageTitle'
import BodyBox from '@/components/BodyBox'
import ExpandableRow from '@/components/ExpandableRow'
export default {
  components:{
    PageTitle,
    BodyBox,
    ExpandableRow
  },
  data() {
    return {
      sections:[
        {
          section: "",
          issues: [
            {
              index: 1,
              title: 'Troopers app is forcing me to update the app. But when I clicked into the Google Play Store or the iOS App Store, There is no ‘download’ button available and instead there was only an ‘open’ button. How do I update the app?',
              contnet: `Sometimes, Google Play Store or the iOS App Store takes awhile to show you the latest Troopers App Update. Clearing the Google Play Store or the iOS App Store cache and data might work. Please follow these steps:
              <br>
              Android:
              <ol>
                <li>Open your device's <strong>Settings</strong> app.</li>
                <li>Tap on <strong>Apps</strong> to see all apps.</li>
                <li>Scroll down and tap on <strong>Google Play Store</strong>.</li>
                <li>Tap on <strong>Storage</strong> then tap on <strong>Clear Cache</strong>.</li>
                <li>Next, tap on <strong>Clear Data</strong>.</li>
                <li>Re-open the <strong>Google Play Store</strong> & find <strong>Troopers</strong> app to try to update again.</li>
              </ol>
              iOS:
              <ol>
                <li>Restart your Iphone</li>
              </ol>
              Or,
              <ol>
                <li>Tap on <strong>Settings</strong>.</li>
                <li>Tap on <strong>iTunes & App Store</strong>.</li>
                <li>Tap on the <strong>Apple ID</strong> menu (it lists the email address you use for your Apple ID).</li>
                <li>In the pop-up menu, tap on <strong>Sign Out</strong>.</li>
                <li>Tap on the <strong>Apple ID</strong> menu again and sign in with your Apple ID.</li>
                <li>Re-open the <strong>iOS App Store</strong> & find <strong>Troopers</strong> app to try to update again.</li>
              </ol>`
            },
            {
              index: 2,
              title: 'I have tried clearing my data and cache but I still could not update the Troopers app. What should I do?',
              contnet: `If you are not rushing, try to wait for an hour or two before trying to clear your data and cache again. If the issue persists, please contact us via <a href="mailto:support@troopers.com.my">support@troopers.com.my</a> and share some of your information with us in order to investigate the issue.
              <br>
              Required information for investigation as follows:
              <ol>
                <li>Device Model(Brand and Model) - Example: Samsung Galaxy S20 Ultra</li>
                <li>OS version - Example: Android 11 or iOS 14</li>
                <li>Current Troopers Version(You can get this information via the 1st screen of the app at the bottom) - Example: 1.2.24</li>
              </ol>`
            },
            {
              index: 3,
              title: "Troopers app keeps showing me the error “Seems like the server is having issues. Please try again later or contact us”. What should I do?",
              contnet: `Usually with this error, it means that the server is having issues and might not able to load any information at the time. Try again in 5 minutes or contact us via <a href="mailto:support@troopers.com.my">support@troopers.com.my</a> and share some of your information with us in order to investigate the issue.
              <br>
              Required information for investigation as follows:
              <ol>
                <li>Device Model(Brand and Model) - Example: Samsung Galaxy S20 Ultra</li>
                <li>OS version - Example: Android 11 or iOS 14</li>
                <li>Current Troopers Version(You can get this information via the 1st screen of the app at the bottom) - Example: 1.2.24</li>
              </ol>`
            },
            {
              index: 4,
              title: "I can’t check in to my job, it keeps showing me the error “Are you at the right location?”. What should I do?",
              contnet: `Firstly, please check you have turned on your location permission for the Troopers app.
              <br>
              Android:
              <ol>
                <li>Open your device's <strong>Settings</strong> app.</li>
                <li>Tap on <strong>Apps</strong> to see all apps.</li>
                <li>Scroll down and tap on <strong>Troopers</strong> app.</li>
                <li>Tap on <strong>Permissions</strong> then tap on <strong>Location</strong>.</li>
                <li>Next, select <strong>Allow only while using the app</strong>.</li>
                <li>Re-open <strong>Troopers</strong> app and try to check in again.</li>
              </ol>
              <br>
              iOS:
              <ol>
                <li>Open <strong>Settings</strong>.</li>
                <li>Go to <strong>Privacy</strong> and then to <strong>Location Services</strong>.</li>
                <li>Make sure <strong>Location Services</strong> is switched on</li>
                <li>Scroll down and tap on <strong>Troopers</strong> app.</li>
                <li>Select <strong>While Using the App</strong> and also enable <strong></strong>Precise Location.</li>
                <li>Re-open <strong>Troopers</strong> app and try to check in again.</li>
              </ol>
              <br>
              Second, try to make sure you are in the right location before checking in. You can do this by viewing the map on top of the <strong>Check In</strong> button, and you must be within the <strong>Check In Zone</strong> which is green in colour. Sometimes, your phone will take some time to update your current location. If so, please wait patiently.
              `
            },
            {
              index: 5,
              title: "I have tried enabling my location permissions for the app and I am within the Check In Zone, but I am still unable to check in. What should I do?",
              contnet: `Try to contact your supervisor to get it sorted for you. You can get your supervisor's contact information in the <strong>My Jobs</strong> page. If a check in photo is required, please send the photo to your supervisor. 
              <br>
              If you could not get your supervisor,
              <ol>
                <li>please go to <strong>Profile</strong> page</li>
                <li>Tap on <strong>Contact Us</strong></li>
                <li>Input title as <strong>Request Manual Check In</strong></li>
                <li>We will validate your information and manually check you in.</li>
              </ol>
              `
            },
            {
              index: 6,
              title: "I can’t launch my camera to take a check in photo. What should I do?",
              contnet: `Firstly, please check you have turned on your camera permission for the Troopers app.
              <br>
              Android:
              <ol>
                <li>Open your device's <strong>Settings</strong> app.</li>
                <li>Tap on <strong>Apps</strong> to see all apps.</li>
                <li>Scroll down and tap on <strong>Troopers</strong> app.</li>
                <li>Tap on <strong>Permissions</strong> then tap on <strong>Camera</strong> and select <strong>Allow only while using the app</strong>.</li>
                <li>Next tap on <strong>Files and media</strong> and select <strong>Allow access to media only</strong>.</li>
                <li>Re-open <strong>Troopers</strong> app and try to check in again.</li>
              </ol>
              iOS:
              <ol>
                <li>Open <strong>Settings</strong>.</li>
                <li>Scroll down and tap on <strong>Troopers</strong> app.</li>
                <li>Tap on <strong>Photos</strong> and enable <strong>Read and Write</strong>.</li>
                <li>Tap on <strong>Camera</strong> and enable it.</li>
                <li>Re-open <strong>Troopers</strong> app and try to check in again.</li>
              </ol>
              `
            },
            {
              index: 7,
              title: "When I was taking a check in photo, the app crashed. What should I do?",
              contnet: `Try to contact your supervisor to get it sorted for you. You can get your supervisor's contact information in the <strong>My Jobs</strong> page. If a check in photo is required, please send the photo to your supervisor.
              <br>
              If you could not get your supervisor,
              <ol>
                <li>please go to <strong>Profile</strong> page</li>
                <li>Tap on <strong>Contact Us</strong></li>
                <li>Input title as <strong>Request Manual Check In</strong></li>
                <li>We will validate your information and manually check you in.</li>
              </ol>
              `
            },
          ]
        },
      ],
      expandedItem: -1
    }
  },
  methods:{
    clickedItem(index){
      console.log(index)
      if(index == this.expandedItem){
        this.expandedItem = -1
      }
      else{
        this.expandedItem = index
      }
    }
  }
}
</script>

<style scoped>
.content-box{
  width: 100%;
  border-radius: .5em;
  position: relative;
  z-index: 1;
  max-width: 50em;
}
.section-box{
  margin-bottom: 1em;
}
.section-title{
  line-height: 1.2em;
  margin-bottom: .3em;
}
.issue{
  margin-bottom: .5em;
}
ol{
  margin-left: .1em;
}
</style>