<template>
    <div @click="clicked" id="btn-cont" :class="enabled?'enabled':'not-enabled'" class="m-t-s">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'btnSubmit',
    props: ['enabled'],
    methods:{
        clicked(){
            if(this.enabled){
                this.$emit('clicked')
            }
        }
    }
}
</script>

<style scoped>
    #btn-cont{
        display: block;
        width: fit-content;
        border-radius: 3px;
        padding: 8px 20px;
        font-weight: 400;
        
    }
    .enabled{
        color: white;
        cursor: pointer;
        background-color: #F36711;
    }
    .not-enabled{
        color: white;
        cursor: pointer;
        background-color: lightgray;
        cursor: no-drop;
    }
</style>