<template>
  <div @keyup.enter="triggerReCaptcha">
    <ContBox class="cont-box">
      <div v-if="!success" class="cont-block">
        <span class="vl-t-s">Please Enter Your Email</span>
        <div class="m-t-s gray-t-c l-t-a">Enter your user account's verified email address and we will send you a password reset link.</div><br>
        <ErrorComment v-if="errorMessage">{{errorMessage}}</ErrorComment>
        <TextBox :title="'Email'" :type="'email'" v-on:valueChanged="email=$event"></TextBox><br>
        <BtnSubmit v-on:clicked="triggerReCaptcha" :enabled="email">Reset Password</BtnSubmit>
      </div>
      <div v-else>A password reset email was sent to your inbox.<br>Please Check your email for password reset instructions</div>
    </ContBox>
    <GoogleRecaptcha ref="recaptcha" action="forgotpassword" @verify="requestSendPasswordReset"/>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import ErrorComment from '@/components/ErrorComment'
import GoogleRecaptcha from '@/components/GoogleRecaptcha.vue'

export default {
  data() {
    return {
      email: null,
      errorMessage: null,
      success: false
    }
  },
  components:{
    ErrorComment,
    GoogleRecaptcha,
  },
  methods:{
    ...mapActions({
      requestResetToken: 'requestResetToken'
    }),
    triggerReCaptcha(){
      this.$refs.recaptcha.execute()
    },
    async requestSendPasswordReset(token){
      this.errorMessage = null
      const resetRequest = await this.requestResetToken({ email: this.email, token })
      if(resetRequest.status==200){
        this.success = true
      }
      else{
        console.log(resetRequest.response.data.message)
        this.errorMessage = resetRequest.response.data.message
      }
    }
  }
}
</script>

<style scoped>
.cont-box{
  max-height: 500px;
  position: absolute;
  transform: translate(-50%,-50%);
  left: 40%;
  top: 50%;
  max-width: 30em;
  
}
</style>