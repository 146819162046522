<template>
  <div id="title-cont">
    <div class="back-cont">
      <div class="back-crcl"></div>
    </div>
    <div>
      <div class="subtitle">{{subtitle}}</div>
      <div style="z-index: 1; position: absolute; top: 0; height: 100%">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:['title','subtitle']
}
</script>

<style scoped>
#title-cont{
  position: relative;
  width: 100vw;
}
.back-cont{
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
.back-crcl{
  position: absolute;
  width: 300vw;
  height: 300vw;
  background-color: #0D1A48;
  bottom: 70vh;
  border-radius: 50%;
  left: -100vw;
}
.title{
  position: relative;
  color: white;
  font-size: 1.5em;
  font-weight: 700;
  text-align: left;
  padding: 1rem 1rem 0% 1rem;
}
.subtitle{
  position: relative;
  color: white;
  font-size: .8em;
  font-weight: 600;
  text-align: left;
  height: 3em;
  display: flex;
  align-items: center;
  margin-left: 1em;
}
</style>