<template>
  <div class="item-cont" @click="$emit('clicked')">
    <div class="title-cont">
      <div class="l-t-a ms-t-s lb-t-w title">{{item.title}}</div>
      <img class="arrow-btn" :class="{'rotate-arrow': item.index == expandedItem}" src="@/assets/simple-arrow-down.svg" alt="down arrow" ref="arrow">
    </div>
    <transition name="expand">
      <div v-if="item.index == expandedItem" v-html="item.contnet" class="l-t-a s-t-s gray-t-c content"></div>
    </transition>
  </div>
</template>

<script>
export default {
  props:['item','expandedItem']
}
</script>

<style scoped>
.item-cont{
  border: 1px rgb(228, 228, 228) solid;
  /* border-radius: .3em; */
  padding: .2em .4em;
}
.item-cont:last-child{
  border-radius: 0 0 .3em .3em;
}
.title-cont{
  display: flex;
  justify-content: space-between;
}
.content{
  border-top: 1px lightgray solid;
  margin-top: 0.5em;
  padding: .8em 0;
}

.arrow-btn{
        transition: all .3s ease-in-out;
        cursor: pointer;
        user-select: none;
    }

    .rotate-arrow{
        transform: rotate(180deg);
    }

.expand-enter-active, .expand-leave-active {
  transition: opacity .3s ease, transform .3s ease, max-height .3s ease, padding .3s ease;
  transform: scale(1);
  max-height: 10em;
  overflow: hidden;
}
.expand-enter, .expand-leave-to{
  opacity: 0;
  transform: scale(0.9);
  max-height: 0;
  padding: 0;
}
</style>