<template>
  <div class="bg-blue-100">
    <div class="p-4 flex gap-2 flex-wrap">
      <img class="mx-auto md:mx-0" src="@/assets/header-logo-white.svg"/>
      <div class="flex flex-wrap gap-4 md:ml-auto">
        <div class="w-full md:w-32">
          <p class="text-white text-md font-bold text-left">Select Jurisdiction:</p>
          <div class="flex border border-gray rounded overflow-hidden items-center bg-white">
            <img v-if="currentCountry" :src="currentCountry.flag" :alt="`${currentCountry.name} flag`"
            class="w-4 h-4 mx-2">
            <div class="self-stretch border-r border-gray"></div>
            <select v-model="selectedCountryName"
            class="flex-1 text-md sm:text-base h-6"
            @input="countryChanged($event)">
              <option v-for="country in countries" :key="country.code" :value="country.name"
                class="block">
                  <div class="flex gap-2">
                    <p>{{country.name}}</p>
                  </div>
              </option>
            </select>
          </div>
        </div>
        <div class="w-full md:w-32">
          <p class="text-white text-md font-bold text-left">Select Language:</p>
          <div class="flex border border-gray rounded overflow-hidden items-center bg-white">
            <select v-model="selectedLanguage"
            class="flex-1 text-md sm:text-base h-6">
              <option v-for="language in languages" :key="language.ID" :value="language.code"
                class="block">
                <p>{{language.displayName}}</p>
              </option>
            </select>
          </div>
        </div>
        <div class="w-full md:w-32">
          <p class="text-white text-md font-bold text-left">T&C Type:</p>
          <div class="flex border border-gray rounded overflow-hidden items-center bg-white">
            <select v-model="selectedTnc"
            class="flex-1 text-md sm:text-base h-6">
              <option v-for="type in tncTypes" :key="type.key" :value="type.key"
                class="block">
                <p>{{type.title}}</p>
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <BodyBox class="content-box">
      <div v-if="tnc.loading" class="flex flex-col gap-2 py-16">
        <p class="text-mid-gray text-xl text-center">Loading...</p>
        <font-awesome-icon icon="spinner" class="animate-spin text-xl text-orange-100"/>
      </div>
      <div v-else v-html="tnc.data" class="content"></div>
    </BodyBox>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import PageTitle from '@/components/PageTitle'
import BodyBox from '@/components/BodyBox'
import ExpandableRow from '@/components/ExpandableRow'

export default {
  name: "terms-n-conditions",
  components:{
    PageTitle,
    BodyBox,
    ExpandableRow
  },
  computed:{
    ...mapGetters({
      countries: "countries",
      currentCountry: "currentCountry",
    }),
    languages(){
      if(!this.currentCountry){
        return([])
      }
      return [{ ID: 2, code: "en", name: "English", displayName: "English" },]
      // return this.currentCountry.languages //TODO: use this once we have all the languages for tnc
    },
    queryTncType(){
      if(this.$route.query.type){
        return this.$route.query.type
      }
      return "troopers_app_tnc"
    }
  },  
  data() {
    return {
      pagePath: "/terms-n-conditions",
      selectedIndex: 0,
      tnc: {
        loading: false,
        data: null
      },
      selectedCountryName: null,
      selectedLanguage: null,
      tncTypes: [
        {
          title: "Troopers App",
          key: "troopers_app_tnc",
        },
        {
          title: "Troopers Protect",
          key: "troopers_protect_tnc",
        },
        {
          title: "Troopers Chat",
          key: "troopers_chat_tnc",
        },
      ],
      selectedTnc: this.queryTncType,
    }
  },
  methods:{
    ...mapActions({
      getSysConfig: 'getSysConfig',
      getCountries: "getCountries",
    }),
    getDataFromConfig(){
      this.tnc = {
        loading: true,
        data: null
      }
      this.getSysConfig(`${this.queryTncType}_${this.selectedLanguage}`)
      .then(res=>{
          this.tnc.data = res.data.value
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        this.tnc.loading = false
      })
    },
    countryChanged(e){
      const newCountryName = e.target.value
      const newCountry = this.countries.find(x=> x.name.toUpperCase() == newCountryName.toUpperCase())
      if(newCountry.name != this.currentCountry.name){
        document.location.href = `${newCountry.webDomain}${this.pagePath}`
      }
    }
  },
  mounted() {
    this.selectedTnc = this.queryTncType
    this.getCountries()
    .then(() => {
      let selectedLanguage = this.languages[0]
      if(this.$route.query.lang){
        selectedLanguage = this.languages.find(x => x.code == this.$route.query.lang.toLowerCase()) || selectedLanguage
      }
      this.selectedLanguage = selectedLanguage.code
    })
  },
  watch: {
    currentCountry(newVal){
      if(newVal){
        this.selectedCountryName = newVal.name
      }
    },
    selectedLanguage(newLanguageCode){
      const newLanguage = this.languages.find(x => x.code == newLanguageCode)
      if(newLanguage && this.$route.query?.lang != newLanguageCode){
        this.$router.push({path: this.pagePath, query: {lang: newLanguageCode}})
      }
      this.$nextTick(() => {
        this.getDataFromConfig()
      })
    },
    selectedTnc(val){
      if(val != this.$route.query.type){
        this.$router.push({path: this.pagePath, query: {...this.$route.query, type: val}})
        this.$nextTick(() => {
          this.getDataFromConfig()
        })
      }
    },
  }
}
</script>

<style scoped>

.content-box{
  width: 100%;
  border-radius: .5em;
  position: relative;
  z-index: 1;
  font-size: .5em;
  text-align: left;
}

.content >>> ol{
  margin-left: 4em !important;
  list-style-type: decimal !important;
}
</style>