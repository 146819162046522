<template>
  <div class="text-center px-4 flex flex-col items-center mt-8 gap-8">
    <img src="@/assets/header-logo.svg" alt="Troopers Logo" class="w-xs animate-pulse">
    <p class="text-lg text-dark-gray bg-white p-3 rounded shadow-center-lg">Please wait while switching to servers</p>
    <font-awesome-icon icon="spinner" class="animate-spin text-2xl text-orange-100"/>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
export default {
  components: { 
  },
  name: "external-login",
  
  computed: {
    ...mapGetters({
      countries: "countries",
    }),
    externalLoginToken(){
      return this.$route.params.loginToken
    }
  },
  methods: {
    ...mapActions({
      loginWithExternalToken: "loginWithExternalToken",
    }),
  },
  mounted(){
    this.loginWithExternalToken(this.externalLoginToken)
    .catch(() => {
      this.$router.push("/login")
    })
  },
}
</script>