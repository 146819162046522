<template>
    <div>
        <div>
            <img src="@/assets/header-logo-2.svg" alt="" class="intro-img only-small">
            <ContBox class="cont-box">
                <div v-if="showForm" class="cont-block">
                    <span class="vl-t-s">Set New Password</span>
                    <ErrorComment v-if="errorMessage">{{errorMessage}}</ErrorComment>
                    <div v-if="validToken">
                        <TextBox :title="'Password'" :type="'password'" v-on:valueChanged="password=$event"></TextBox><br>
                        <TextBox :title="'Confirm Password'" :type="'password'" v-on:valueChanged="confirmedPassword=$event"></TextBox><br>
                        <BtnSubmit v-on:clicked="requestSetPassword" :enabled="password&&password==confirmedPassword">Set Password</BtnSubmit>
                    </div>
                </div>
                <div v-if="chatAppRedirect" class="chat-redirect">
                    <span>Please login using Troopers Chat app or download it from the link below.</span>
                    <div class="page-sec">
                        <div>
                            <div class="app-btns">
                                <a target="_blank" :href="`https://play.google.com/store/apps/details?id=my.com.troopers.parttime.android`">
                                    <img class="android-btn" src="@/assets/android-button.png" alt="">
                                </a>
                                <a target="_blank" :href="`https://apps.apple.com/us/app/troopers-part-time-made-easy/id1541999583`">
                                    <img src="@/assets/ios-button.png" alt="">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="showSuccessMessage">Your password has been reset successfully.</div>
                <div class="col-sep only-large"></div>
                <div class="cont-block only-large">
                    <img src="@/assets/ptme new logo2.svg" alt="" class="intro-img">
                </div>
            </ContBox>
        </div>
    </div>
</template>

<script>
import {mapActions} from 'vuex'
import ErrorComment from '@/components/ErrorComment'
export default {
    name: 'login',
    data(){
        return{
            validToken: false,
            password: null,
            confirmedPassword: null,
            errorMessage: null,
            showForm: true,
            showSuccessMessage: false,
            chatAppRedirect: false,
        }
    },
    components:{
        ErrorComment
    },
    methods:{
        ...mapActions({
            setPassword:'setPassword',
            checkToken:'checkToken',
        }),
        async requestSetPassword(){
            this.errorMessage = null
            let res = await this.setPassword({password: this.password, token: this.$route.params.token})
            if(res.status==200){
                if(res.data.userType=='part timer'){
                    this.showForm = false;
                    this.showSuccessMessage = true;
                }
                else if(res.data.userType=='guest'){
                    this.$router.push("/guest")
                }
                else{
                    this.$router.push("/login");
                }
            }
            else{
                this.errorMessage = res.response.data.message
            }
        }
    },
    async mounted(){
        let res = await this.checkToken(this.$route.params.token)
        if (res.status == 200){
            this.validToken = true
            this.errorMessage = null
        }
        else {
            this.validToken = false
            this.errorMessage = res.response.data.message
        }
        // this.validToken = true
        // this.errorMessage = null
    }
}
</script>

<style scoped>
    .cont-box{
        display: flex;
        max-height: 500px;
        position: absolute;
        transform: translate(0,-50%);
        top: 50%;
    }
    .col-sep{
        height: auto;
        width: 1px;
        background-color: #CCCCCC;
    }
    .intro-img{
        width: 100%;
        max-height: 300px;
    }
    .cont-block{
        width: 100%;
        margin: 5%;
    }
    @media screen and (max-width: 600px) {
        .intro-img{
            max-width: 50%;
            padding-top: 10%;
        }
        .chat-redirect{
            font-size: large !important;
        }
    }
    @media screen and (min-width: 600px) {
        .chat-redirect{
            padding-right: 1em;
        }
    }
    .chat-redirect{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: x-large;
    }
    .page-sec{
        padding-top: 1.5em;
    }
    .app-btns{
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        gap: 1em;
        margin: 10px auto;
    }
    .app-btns>*>*{
        width: 185px;
        height: 60px;
    }
    .android-btn{
        border: 1px #a1a1a1 solid;
        border-radius: 5px;
    }
</style>