<template>
  <div id="app">
    <router-view/>
    <transition name="topin">
      <topMessageBar v-if="topMessageText"
      v-on:exit="changeTopMessage({text: null, status: null})">{{topMessageText}}</topMessageBar>
    </transition>
  </div>
</template>

<script>
import {mapState, mapGetters, mapMutations, mapActions} from 'vuex'
import topMessageBar from '@/components/topMessageBar'
export default {
  name: "app",
  components:{
    topMessageBar,
    topMessageText: 'topMessageText',
  },
  computed:{
    ...mapGetters({
      topMessageText: 'topMessageText',
    }),
  },
  methods:{
    ...mapMutations({
      changeTopMessage: 'changeTopMessage'
    }),
  },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400&display=swap');
#app {
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #0D1A48;
}
html {
  box-sizing: border-box;
  font-size: 1.5em;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body, h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

ul {
  margin: 0;
  padding: 0 0 0 1em;
}

img {
  height: auto;
}

#full-back{
  position: fixed;
  z-index: -999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}



/* Standards */
.vs-t-s{
  font-size: 0.5em;
}
.s-t-s{
  font-size: 0.6em;
}
.ms-t-s{
  font-size: 0.7em;
}
.m-t-s{
  font-size: 0.8em;
}
.l-t-s{
  font-size: 1em;
}
.vl-t-s{
  font-size: 1.3em;
}

.b-t-w{
  font-weight: 700;
}
.lb-t-w{
  font-weight: 600;
}

.l-t-a{
  text-align: left;
}
.r-t-a{
  text-align: right;
}
.c-t-a{
  text-align: center;
}


.primary-t-c{
  color: #0D1A48;
}
.title-t-c{
  color: #F36711;
}
.gray-t-c{
  color: #888888;
}
.error-t-c{
  color: #ff4747;
}

.gray-b-c{
  background-color: #888888;
}

.light-b-c{
  background-color: #f5f5f5;
}

.white-b-c{
  background-color: #FFFFFF;
}

.sep{
  height: 1px;
  background-color: lightgray;
  width: 80%;
  margin: auto;
}
.ql-align-center {
    text-align: center;
}
@media only screen and (max-width: 600px) {
  .only-large{
    display: none !important;
  }
  .dual-large{
    width: 80%;
  }
}

@media only screen and (min-width: 600px) {
  .only-small{
    display: none;
  }
  .dual-large{
    width: 40%;
  }
}

/* Transitions */

.fade-enter-active, .fade-leave-active {
  transition: opacity .2s ease-in-out, transform .2s ease-in-out;
  transform: scale(1);
}
.fade-enter, .fade-leave-to{
  opacity: 0;
  transform: scale(0.9);
}


.slide-enter-active, .slide-leave-active {
  transition: margin-right .3s ease-out;
  position: fixed;
  top: 0;
  margin-right: 0%;
}
.slide-enter, .slide-leave-to{
  transition: margin-right .3s ease-in;
  margin-right: -100%;
  position: fixed;
}


.topin-enter-active, .topin-leave-active {
  transition: opacity .3s ease-in-out, margin-top .3s ease-in-out;
  position: fixed;
  margin-top: 0;
}
.topin-enter, .topin-leave-to{
  opacity: 0;
  margin-top: -2em;
}

</style>
