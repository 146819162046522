<template>
    <div class="text-box-cont">
        <div class="title-cont">
            <div v-if="title" class="m-t-s b-t-w">{{title}}<span style="color: red;">{{required?'*':''}}</span></div>
            <div v-if="errMsg" class="err-msg s-t-s">*{{errMsg}}</div>
        </div>
        <textarea v-if="type=='textarea'"  v-model="inputValue" style="width: 100%; height: 8em;" v-on:input="commitValue"></textarea>
        <input v-else ref="inputText" v-model="inputValue" class="text-box m-t-s" :type="getType(type)" :placeholder="placeHolder"
        :onfocus="placeHolder&&type=='dateHolder'?'(this.type=`date`)':''"
        :onblur="placeHolder&&type=='dateHolder'?'(this.type=`text`)':''" v-on:input="commitValue"
        @blur="validate" 
        @focus="clearErr"
        @keyup="keyUp($event)"
        :min="min"
        :max="max"
        :disabled="disabled">
        <!-- <span v-if="errMsg" class="err-msg s-t-s only-small">*{{errMsg}}</span> -->
    </div>
</template>

<script>
export default {
    props: ['title', 'type', 'initVal', 'min', 'max', 'placeHolder','disabled', 'required'],
    data(){
        return{
            inputValue: null,
            errMsg: null
        }
    },
    methods: {
        commitValue(){
            this.$emit('valueChanged', this.inputValue)
        },
        validate(){
            switch (this.type) {
                case 'email':
                    {
                        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        if(!re.test(String(this.inputValue).toLowerCase())){
                            this.errMsg = "Invalid email!"
                            this.$emit('valueChanged', null)
                        }
                    }break;
                case 'password':
                    {
                        if(this.inputValue.length<7){
                            this.errMsg='Password must be at least 7 characters'
                            this.$emit('valueChanged', null)
                        }
                        else if(this.inputValue.length>52){
                            this.errMsg='Password must be less than 52 characters'
                            this.$emit('valueChanged', null)
                        }
                        else if(!/[A-Z]/.test(this.inputValue)){
                            this.errMsg='Password must contain at least one Upper Case letter'
                            this.$emit('valueChanged', null)
                        }
                        else if(!/[a-z]/.test(this.inputValue)){
                            this.errMsg='Password must contain at least one Lower Case letter'
                            this.$emit('valueChanged', null)
                        }
                        else if(!/[0-9]/.test(this.inputValue)){
                            this.errMsg='Password must contain at least one number'
                            this.$emit('valueChanged', null)
                        }
                    }break;
                case 'text':
                    {
                        if(this.inputValue.length<1){
                            this.errMsg='Must be at least 1 characters long'
                            this.$emit('valueChanged', null)
                        }
                        else if(this.inputValue.length>255){
                            this.errMsg='Must be less than 255 characters'
                            this.$emit('valueChanged', null)
                        }
                    }break;
                case 'number':
                    {
                        if(this.min!=null){
                            if(parseFloat(this.inputValue)<this.min){
                                this.errMsg='Minimum '+this.min
                            }
                        }
                        if(this.max!=null){
                            if(parseFloat(this.inputValue)>this.max){
                                this.errMsg='Maximum '+this.max
                            }
                        }
                    }break;
                default:
                    break;
            }
        },
        getType(type){
            switch (type) {
                case 'text':return('text');break;
                case 'password':return('password');break;
                case 'loginPassword':return('password');break;
                case 'email':return('email');break;
                case 'number':return('number');break;
                case 'date':return('date');break;
                case 'time':return('time');break;
                case 'dateHolder':return('text');break;
            
                default:
                    break;
            }
        },
        clearErr(){
            this.errMsg = null
        },
        keyUp(e){
            if(e.code=="Enter"){
                this.$emit('submit')
            }
        },
        clear(){
            this.inputValue = null
            this.$emit('valueChanged', null)
        }
    },
    mounted(){
        if(this.initVal){
            this.inputValue = this.initVal
        }
        if(this.min){
        }
    },
    watch:{
        initVal(val){
            this.inputValue=val
        }
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');

.text-box{
    font-family: 'Poppins', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #444444;
    border-radius: 5px;
    border: 1px #e2e2e2 solid;
    background-color: #F9FBFC;
    padding: 5px 10px;
    width: 100%;
    height: 2.5em;
}
.text-box-cont{
    position: relative;
    text-align: left;
}
.title-cont{
    display: flex;
    flex-wrap: wrap;
}
.err-msg{
    right: 0;
    color: red;
    margin-top:0.5em ;
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: lightgray;
  /* font-style: italic; */
}
</style>