<template>
  <div style="background-color:#F5F5F5; ">
    <div style="min-height: 100vh; display: flex; flex-direction: column;">
    <div class="top-banner"><img src="@/assets/new-troopers-full-logo.svg" alt="" class="logo" style=""></div>
    <BodyCont v-if="jobPostData && jobPostData != 'fetching'" class="body ms-t-s">
      <div class="section-left">
        <BodyBox style="overflow: visible; width: 100%; padding: 0; margin-bottom: 1em; ">
          <div class="job-post-info l-t-a">
            <div class="img-cont"><img v-if="jobPostData.Image" class="job-post-img" :src="jobPostData.Image" alt=""></div>
            <div class="info-cont">
              <div class="title l-t-a">
                <span class="ms-t-s" style="color: black;">{{jobPostData['Job Code']}}</span>
                <span class="l-t-s b-t-w">{{jobPostData.Title}}</span>
              </div>
              <div class="content">
                <div>
                  <img src="@/assets/dollar-sign-icon.svg" alt="" class="logo">
                  <div class="stack">
                    <span class="m-t-s b-t-w title-t-c" style="margin-right: .5em;">{{jobPostData.Salary}}<span> for {{calcDuration(jobPostData['Shift Starting Time'], jobPostData['Shift Ending Time'])}} hours</span></span>
                    <span class="ms-t-s gray-t-c">(Payment Term -  {{jobPostData['Payment Terms'].paymentTermsDays}} Days)</span>
                  </div>
                </div>
                <div>
                  <img src="@/assets/calendar-icon.svg" alt="" class="logo">
                  <div class="stack">
                    <span class="m-t-s" style="margin-right: .5em;">{{customFormatDate(jobPostData['Starting Date'], 'DD MMM YYYY')}} - {{customFormatDate(jobPostData['Ending Date'], 'DD MMM YYYY')}}</span>
                    <span class="m-t-s">({{formatTime(jobPostData['Shift Starting Time'])}} - {{formatTime(jobPostData['Shift Ending Time'])}})</span>
                  </div>
                </div>
                <div>
                  <img src="@/assets/conversion-icon.svg" alt="" class="logo">
                  <span class="m-t-s">Early Conversion</span>
                </div>
                <div v-if="jobPostData['categories'].length">
                  <img src="@/assets/category-icon.svg" alt="" class="logo">
                  <div class="job-cat-tag-cont">
                    <div v-for="(categories, index) in jobPostData['categories']" :key="index">
                      <div class="job-cat-tag-capsules ms-t-s"	:style="'background-color: ' + categories.backgroundColor + '; color: ' + categories.fontColor + '; border: 1px solid ' + categories.borderColor">{{categories.name}}</div></div>
                  </div>
                </div>
                <div v-if="jobPostData['tags'].length">
                  <img src="@/assets/tag-icon.svg" alt="" class="logo">
                  <div class="job-cat-tag-cont">
                    <div v-for="(tags, index) in jobPostData['tags']" :key="index">
                      <div class="job-cat-tag-capsules ms-t-s"	:style="'background-color: ' + tags.backgroundColor + '; color: ' + tags.fontColor" >{{tags.name}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </BodyBox>
        <BodyBox v-if="jobPostData['Job Languages'].length" style="overflow: visible; width: 100%; margin-bottom: 1em;">
          <div class="languages l-t-a">
            <div class="l-t-s b-t-w main-title">Languages</div>
              <div v-for="(language, index) in jobPostData['Job Languages']" :key="index" style="display: flex; gap:.5em; padding: 0.25em 0">
                <div class="m-t-s" style="width: 4em">{{language.Language}} </div>
                <div><LanguageRating :languageDetails="language" :size="'small'"></LanguageRating></div>
            </div>
          </div>
        </BodyBox>
        <BodyBox style="overflow: visible; width: 100%; margin-bottom: 1em;">
          <div class="location">
            <div class="main-title l-t-a l-t-s b-t-w">Location</div>
              <div class="ms-t-s l-t-a">
                <ul v-for="(item, index) in jobPostData.locations" :key="index"  style="padding: 0; list-style-position: inside;">
                <li class="b-t-w">{{item.name}}</li>
                <div style="margin-left:1.5em; margin-bottom: .5em;"><a :href="'https://www.google.com/maps/search/?api=1&query='+item.name" target="_blank">{{item.address}}</a></div>
                </ul>
              </div>
            <div>
              <google-map class="map-view"
                :search="false"
                :selectedLocations="locations"
              />
            </div>
          </div>
        </BodyBox>
      </div>
      <div class="section-right">
        <BodyBox style="overflow: visible; width: 100%; margin-bottom: 1em;">
          <div class="job-description l-t-a">
            <div class="main-title l-t-s b-t-w">Job Description</div>
            <div class="ms-t-s" v-html="jobPostData.Description"></div>
          </div>
        </BodyBox>
        <BodyBox v-if="jobPostData['Job Dynamic Requirements'].length>0" style="overflow: visible; width: 100%; margin-bottom: 1em;">
          <div class="requirements l-t-a">
            <div class="main-title l-t-s b-t-w">Requirements</div>
              <ul v-for="(item, index) in jobPostData['Job Dynamic Requirements']" :key="index" ><li class="ms-t-s">{{item.Requirements}}</li></ul>
          </div>
        </BodyBox>
      </div>
    </BodyCont>
    <BodyCont v-if="!jobPostData && jobPostData != 'fetching'" class="not-found-cont">
      <div  class="not-found-title">
        <img src="@/assets/404.svg" alt="">
      </div>
      <div class="">
        OOPS! THE PAGE YOU WERE LOOKING FOR, COULDN'T BE FOUND.
      </div>
      <div >
        <img class="not-found-img" src="@/assets/designers.svg" alt="">
      </div>
    </BodyCont>
    
    </div>
    <div class="bottom-banner">
      <div class="apply-now">
        <div class="desc" ><div class="m-t-s b-t-w" style="line-height:1.2em">Apply Now?</div><div class="vs-t-s" style="line-height:1em">Download the app</div></div>
        <div class="app-btns">
          <a target="_blank" :href="`https://play.google.com/store/apps/details?id=my.com.troopers.parttime.android`">
            <img class="android-btn" src="@/assets/android-button.png" alt="">
          </a>
          <a target="_blank" :href="`https://apps.apple.com/us/app/troopers-part-time-made-easy/id1541999583`">
            <img src="@/assets/ios-button.png" alt="">
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import PageTitle from '@/components/PageTitle'
import BodyBox from '@/components/BodyBox'
import BodyCont from '../components/BodyCont.vue'
import LanguageRating from '@/components/LanguageRating'
import GoogleMap from "@/components/GoogleMap";

export default {
  name: 'Home',
  components:{
    PageTitle,
    BodyBox,
    BodyCont,
    BodyCont,
    LanguageRating,
    GoogleMap
},
  data() {
    return {
      jobPostData: null
    }
  },
  computed:{
    locations(){
      let locations = []
      
      for (const location of this.jobPostData?.locations) {
        locations.push(
          {
            position:{
              lat: location.locationLat,
              lng: location.locationLon,
            }
          }
        )
      }
      return locations
    }
  },
  methods:{
    ...mapActions({
      getMyProfile: 'getMyProfile',
      getJobPost: 'getJobPost'
    }),
    getJobPostData(){
      this.jobPostData = 'fetching'
      this.getJobPost(this.$route.params.jobPostID)
      .then(res=>{
        this.jobPostData = res.data
      })
      .catch(err => {
        console.log(err)
      })
    }
  },
  async mounted(){
    this.getJobPostData();
    let results = await this.getMyProfile();
    if(results.status==200){
      if(results.data.Type == 'supervisor'){
        window.location.href = '/supervisor'
      }
      else if(
        results.data.Type == 'admin'||
        results.data.Type == 'employer'||
        results.data.Type == 'recruiter'||
        results.data.Type == 'finance'
      ){
        if (this.$route.params.jobPostID) {
          window.location.href = `/cms/jobpost/${this.$route.params.jobPostID}` 
          return
        }
        window.location.href = '/cms'
      } 
    }; 
  }
}
</script>

<style scoped>
.top-banner{
  display: flex;
  justify-content: center;
  padding: .5em;
  border-bottom: 1px solid lightgrey;
  box-shadow: 0 0 .3em rgba(0, 0, 0, .2);
  top: 0;
  background: white;
  z-index: 1;
}
.top-banner .logo{
  width: 7em;
}
.body{
  width: 75%;
  display: flex;
  flex-direction: row;
  gap:1em;
}
.main-title{
  margin-bottom: 1em;
}
.section-left{
  width: 40vw;
}
.job-post-info .content{
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  gap: .5em;
}
.job-post-info .logo{
  width: 1.2em;
  margin-right: .55em;
}
.job-post-info .content > div{
  display: flex;
  align-items: flex-start;
}
.job-post-info .stack{
  display: flex;
  flex-wrap: wrap;
}
.job-cat-tag-cont{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: 0.4em;
}
.job-cat-tag-capsules{
  /* font-size: 0.65em; */
  text-align: center;
  min-width: fit-content;
  padding: 0.2em 0.85em;
  margin: 0 .2em 0.1em 0;
  border-radius: 0.35em;
}
.section-right{
  width: 100%;
}
.img-cont{
  aspect-ratio: 16/7;
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
  border-top-left-radius: .5em;
  border-top-right-radius: .5em;
  background: rgb(245 245 245);
}
.job-post-img {
  height: 100%;
  text-align: center;
  font-weight: 700;
  color: gray;
  overflow: hidden;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}
.title{
  display: flex;
  flex-direction: column;
}
.info-cont{
  padding: 1em;
}
.apply-now{
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: .5em;
  /* margin-top:.5em; */
}

.app-btns{
  display: flex;
  gap: .5em;
  line-height: 0;
}
.app-btns>*>*{
  width: 130px;
}
.map-view{
  width: 100%;
  height: 15em;
  margin-top: 1em;
}
a:link {
  color:blue;
  text-decoration:underline;
}
a:hover {
  cursor: pointer;
}
.bottom-banner{
  display: flex;
  justify-content: center;
  padding: .5em;
  border-top: 1px solid lightgrey;
  box-shadow: 0 0 .3em rgba(0, 0, 0, .2);
  position: sticky;
  bottom: 0;
  background: white;
  z-index: 1;
}
.not-found-cont{
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.not-found-title{
  width: 10em;
}

@media screen and (max-width: 390px) {
  .bottom-banner{
    padding-top: 0.25em;
    padding-bottom: 0.25em;
  }
  .app-btns>*>*{
    width: 100px;
  }
  .apply-now{
    font-size: small;
    flex-direction: column;
    gap: .25em;
  }
  .not-found-title{
    width: 10em;
  }
  .not-found-img{
    width: 100vw;
    object-fit: contain;
  }
}
@media screen and (max-width: 455px) {
  .app-btns>*>*{
    width: 115px;
  }
  .apply-now{
    font-size: smaller;
  }
}
@media screen and (max-width: 600px) {
  .body{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0em;
  }
  .section-left{
    width: 100%;
  }
}
@media screen and (max-width: 850px) {
  .body{
    display: flex;
    flex-direction: column;
    gap: 0em;
  }
  .section-left{
    width: 100%;
  }
}
@media screen and (min-width: 850px) and (max-width: 1000px) {
  .body{
    width: 90%;
    display: flex;
  }
}
</style>